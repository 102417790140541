

























import { Button, Cell, Image } from "vant";
import F2 from "@antv/f2/lib/index-all";
import Total from "./components/total.vue";
import moment from "moment";
import { defineComponent } from "@vue/composition-api";
moment.locale("zh-cn");

interface Stat {
  date: number;
  amount: number;
  count: number;
  incomeAmount: number;
  refundAmount: number;
}

export default defineComponent({
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
    [Cell.name]: Cell,
    Total
  },
  data() {
    return {
      siteId: 0,
      site: {
        id: 0,
        name: ""
      },
      shareId: (this.$route.query.shareId || 0) as number,
      // stat!: Stat;
      stat: {} as Stat,
      list: [] as Stat[],

      today: moment()
        .startOf("day")
        .valueOf(),
      startTime:
        moment()
          .startOf("day")
          .valueOf() -
        86400 * 1000 * 7,
      endTime: moment()
        .startOf("day")
        .valueOf()
    };
  },
  computed: {
    // get totalData() {
    totalData(): null | { title: string; total: { value: number; unitPrefix: string }; list: { value: number; unit: string; label: string; image: string }[] } {
      if (!this.stat || this.$isEmptyObject(this.stat)) return null;
      return {
        title: moment(this.stat.date).format("YYYY-MM-DD"),
        total: {
          value: this.stat.amount,
          unitPrefix: "¥"
        },
        list: [
          { value: this.stat.incomeAmount, unit: "元", label: "收入", image: "/images/stat/icon-stat-income.png" },
          { value: this.stat.refundAmount, unit: "元", label: "退款", image: "/images/stat/icon-stat-refund.png" },
          { value: this.stat.count, unit: "元", label: "笔数", image: "/images/stat/icon-stat-other.png" }
        ]
      };
    },
    // get isLast7days() {
    isLast7days(): boolean {
      const start = moment()
        .startOf("day")
        .subtract(7, "day")
        .valueOf();
      return this.startTime == start && this.endTime == this.today;
    },
    // get isLast30days() {
    isLast30days(): boolean {
      const start = moment()
        .startOf("day")
        .subtract(30, "day")
        .valueOf();
      return this.startTime == start && this.endTime == this.today;
    },
    // get isLastHalfYear() {
    isLastHalfYear(): boolean {
      const start = moment()
        .startOf("day")
        .subtract(6, "month")
        .valueOf();
      return this.startTime == start && this.endTime == this.today;
    }
  },
  watch: {
    startTime: "onTimeChange",
    endTime: "onTimeChange"
  },
  mounted() {
    this.siteId = Number(this.$route.query.siteId) || 0;
    this.getData();
    // this.renderChart();
  },
  methods: {
    getData() {
      const postData = {
        startTime: this.startTime,
        endTime: this.endTime,
        siteId: this.siteId,
        shareId: this.shareId
      };
      this.$axios.post("/api/manage/account/getAccountSiteTrending", postData).then(res => {
        const data = res.data.data;
        this.site = data.site;
        this.list = data.list;
        this.stat = this.list[this.list.length - 1];
        this.renderChart();
      });
    },
    renderChart() {
      const chart = new F2.Chart<Stat>({
        id: "chart",
        pixelRatio: window.devicePixelRatio
      });
      chart.source(this.list);
      chart.scale("date", {
        formatter: date => {
          return moment(date).format("MM-DD");
        },
        tickCount: 7,
        min: moment()
          .subtract(7, "day")
          .valueOf(),
        max: moment().valueOf()
      });
      chart.tooltip({
        alwaysShow: false,
        showCrosshairs: true,
        showTooltipMarker: true,
        tooltipMarkerStyle: {
          fill: "#f60",
          stroke: "#fff"
        },
        custom: true,
        showXTip: true,
        onShow: ev => {
          const item = ev.items[0];
          this.stat = item.origin;
        }
      });
      chart
        .line()
        .position("date*amount")
        .color("#f60")
        .shape("smooth");

      chart.interaction("pan");
      chart.interaction("pinch", {
        maxScale: 5,
        minScale: 1
      });
      // 定义进度条
      chart.scrollBar({
        mode: "x",
        xStyle: {
          offsetY: -5
        }
      });

      chart.render();

      // const point = chart.getPosition(this.stat); // 获取该数据的画布坐标
      // chart.showTooltip(point); // 展示该点的 tooltip
    },
    onPickSite(site: { id: number; name: string }) {
      this.siteId = site.id;
      this.getData();
    },
    onTimeChange() {
      this.getData();
    },
    filterLast7days() {
      const start = moment()
        .startOf("day")
        .subtract(7, "day")
        .valueOf();
      this.startTime = start;
      this.endTime = this.today;
    },
    filterLast30days() {
      const start = moment()
        .startOf("day")
        .subtract(30, "day")
        .valueOf();
      this.startTime = start;
      this.endTime = this.today;
    },
    filterLastHalfYear() {
      const start = moment()
        .startOf("day")
        .subtract(6, "month")
        .valueOf();
      this.startTime = start;
      this.endTime = this.today;
    }
  }
});
